import { IFranchiseSchool } from './models/agency.interface';
import { IStudentSchoolData, IStudentSchoolDataDTO } from './models/student.interface';

export function isAgencySchool(
    school:
        | {
              id: number;
          }
        | IFranchiseSchool
        | IStudentSchoolData
        | IStudentSchoolDataDTO
): school is IFranchiseSchool {
    return (school as IFranchiseSchool)?.id !== undefined;
}

export function isCustomSchool(
    school:
        | {
              id: number;
          }
        | IFranchiseSchool
        | IStudentSchoolData
        | IStudentSchoolDataDTO
): school is IStudentSchoolData | IStudentSchoolDataDTO {
    return (
        (
            school as {
                id: number;
            }
        )?.id === undefined
    );
}
